<div class="entry">
    <div class="entryBox" (click)="showDetails()" [class]="entry.result">
        <div class="header center">
        @switch (entry.type)
        {
            @case (teamsType.phoneNumber) {<img class="icons" [class]="entry.result" src="./image/phone.png" />}
            @case (teamsType.microsoftBot) {<img class="icons" [class]="entry.result" src="./image/group.png" />}
            @case (teamsType.microsoftTeamsApp) {<img class="icons" [class]="entry.result" src="./image/group.png" />}
            @case (teamsType.microsoftTeamsUser) {<img class="icons" [class]="entry.result" src="./image/XRM_Teams_Icon.svg">}
        }
        </div>
        <div class="header">
            {{entry.displayName}}
            <div>
                @if(entry.displayName != entry.number && entry.displayName!='')
                {
                    <small>{{ entry.number }}</small>
                }
                @else {
                    {{ entry.number }}
                }
            </div>
        </div>
        <div class="info">
            <small>{{entry.timestamp | date:'HH:mm:ss'}}</small>
        </div>
    </div>
</div>
@if(isDetailsVisible)
{
    <div class="details">
        @if(entry.crmData.actions?.length != 0)
        {
            <app-action-buttons [actions]="entry.crmData.actions!"></app-action-buttons>
        }

        @if(entry.acceptedBy.length > 0 || entry.transferredBy.length > 0 || entry.forwardedBy.length > 0)
        {
            <div class="alert information">
                @if(entry.acceptedBy.length > 0){<div>Accepted by: {{entry.acceptedBy}}</div>}
                @if(entry.transferredBy.length > 0){<div>Transferred by: {{entry.transferredBy}}</div>}
                @if(entry.forwardedBy.length > 0){<div>Forwarded by: {{entry.forwardedBy}}</div>}
            </div>
        }

        @if(entry.crmData.custom?.length != 0)
        {
            <app-data-table [entry]="entry.crmData.custom!" />
        }
        @else {
            <div>No contact information available</div>
        }
    </div>
}
