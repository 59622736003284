@if(call)
{
<div class="container">
    <div class="callNotify">
        @if(call.type === teamsType.phoneNumber && !call.number.startsWith('anonym'))
        {
        <a (click)="openPhone(call.number)">{{call.number}}</a>
        }
        @else {
        <div>{{call.number}}</div>
        }

        @if(call.result === result.Ongoing)
        {
        <div class="call">
            <div class="resultIcons">
                <span>
                    @switch (call.type) {
                    @case (teamsType.phoneNumber) {<img class="notifyCallImage" src="image/phone.png" />}
                    @case (teamsType.microsoftTeamsApp||teamsType.microsoftBot) {<img class="notifyCallImage"
                        src="image/group.png" />}
                    @case (teamsType.microsoftTeamsUser) {<img class="notifyCallImage"
                        src="image/XRM_Teams_Icon.svg" />}
                    }
                </span>
            </div>
        </div>
        }
        @else {
        <div class="call" [ngSwitch]="call.type">
            <div class="resultIcons">
                <img class="icons" *ngSwitchCase="teamsType.phoneNumber"
                    [ngClass]="{ accepted: call.result === result.Accepted, declined: call.result === result.Declined, unanswered: call.result === result.Unanswered}"
                    src="image/phone.png" />
                <img class="icons" *ngSwitchCase="teamsType.microsoftBot"
                    [ngClass]="{ accepted: call.result === result.Accepted, declined: call.result === result.Declined, unanswered: call.result === result.Unanswered}"
                    src="image/group.png" />
                <img class="icons" *ngSwitchCase="teamsType.microsoftTeamsApp"
                    [ngClass]="{ accepted: call.result === result.Accepted, declined: call.result === result.Declined, unanswered: call.result === result.Unanswered}"
                    src="image/group.png" />
                <img class="icons" *ngSwitchCase="teamsType.microsoftTeamsUser"
                    [ngClass]="{ accepted: call.result === result.Accepted, declined: call.result === result.Declined, unanswered: call.result === result.Unanswered}"
                    src="image/XRM_Teams_Icon.svg" />
            </div>
        </div>}
        {{call.timestamp | date:'dd MMM | HH:mm:ss'}}

    </div>
    <div class="details">
        @if(call.displayName.length > 0 && call.displayName != call.number && crmData.length === 0)
        {
        <div class="detail">
            <div class="title">
                Name
            </div>
            <div class="detailVal">
                <div class="value shorten" [tooltip]="call.displayName">
                    {{call.displayName}}
                </div>
            </div>
        </div>
        }


        @for(key of crmData | slice:0:3; track $index)
        {
        <div class="detail" [ngSwitch]="key.type">
            <div class="title">
                {{key.title}}
            </div>
            <div class="detailVal">
                <div class="value shorten" [tooltip]="key.value">
                    @switch (key.type) {
                    @case (crmDataType.Mail) {
                    <a href="mailto:{{key.value}}">{{key.value}}</a>
                    }
                    @case (crmDataType.Link) {
                    <a (click)="openLink(key.value)">{{key.value}}</a>
                    }
                    @default {
                    {{key.value}}
                    }
                    }
                </div>
                <app-copy-button [val]="key.value" />
            </div>
        </div>
        }

    </div>
</div>
}
@else {
<div class="container empty">
    Welcome to XRMFusion
</div>
}