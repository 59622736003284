import { Injectable } from '@angular/core';
import { baseLayerLuminance } from '@fluentui/web-components';
import { DesignToken } from '@microsoft/fast-foundation';
import { WebViewInteropService } from './web-view-interop.service';
import { environment } from 'src/environments/environment';

//#region DesignTokens
export const layer = document.querySelector('body') as HTMLElement;
export const specialBackground = DesignToken.create<string>('theme-background-color');
export const entryBackground = DesignToken.create<string>('theme-entry-background-color');
export const entryDetailsBackground = DesignToken.create<string>('theme-entry-details-background-color');
export const warningColor = DesignToken.create<string>('theme-warning-background-color');
export const warningFontColor = DesignToken.create<string>('theme-warning-font-color');
export const infoColor = DesignToken.create<string>('theme-info-background-color');
export const infoFontColor = DesignToken.create<string>('theme-info-font-color');
export const errorColor = DesignToken.create<string>('theme-error-background-color');
export const errorFontColor = DesignToken.create<string>('theme-error-font-color');
export const teamsColor = DesignToken.create<string>('theme-accent-background');
export const teamsFontColor = DesignToken.create<string>('theme-accent-foreground');
//#endregion

export enum Theme{
  Light = "light",
  Dark = "dark",
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  theme : Theme = Theme.Light;
  constructor(private interop: WebViewInteropService) { }

  Init() {
    // we have to return a promise here, so the app will wait until this gets resolve and continue to bootstrap
    return new Promise<void>((resolve, reject) => {
      console.log("Initialize. Web version: ", environment.version);
      
      specialBackground.setValueFor(layer, "white");
      entryBackground.setValueFor(layer, "#f4f4f4");
      entryDetailsBackground.setValueFor(layer, "#e3e2e2");
      warningColor.setValueFor(layer, "#fef7b2");
      warningFontColor.setValueFor(layer, "#fdb913");
      infoColor.setValueFor(layer, "#a7e3a5");
      infoFontColor.setValueFor(layer, "#13a10e");
      errorColor.setValueFor(layer, "#eeacb2");
      errorFontColor.setValueFor(layer, "#ec0e3f");
      teamsColor.setValueFor(layer,"#5b5fc7");
      teamsFontColor.setValueFor(layer, "white");

      this.setTheme();
      resolve();
    });
  }

  changeTheme(newTheme: Theme){
    switch(newTheme)
    {
      case Theme.Light:
        localStorage.setItem('theme', Theme.Light);
        break;
      case Theme.Dark:
        localStorage.setItem('theme', Theme.Dark);
        break;
      default:
        localStorage.setItem('theme', Theme.Light);
        break;
    }
    this.setTheme();
  }

  setTheme(){
    this.theme = <Theme>localStorage.getItem('theme'); //Cast string to enum
    switch(this.theme)
    {
      case Theme.Light:
        baseLayerLuminance.setValueFor(layer, 1);
        specialBackground.setValueFor(layer, 'white');
        entryBackground.setValueFor(layer, '#f4f4f4');
        entryDetailsBackground.setValueFor(layer, '#e3e2e2');
        this.interop.postMessageToClient("setLightTheme");
        break;
      case Theme.Dark:
        baseLayerLuminance.setValueFor(layer, 0.1);
        specialBackground.setValueFor(layer, '#1f1f1f');
        entryBackground.setValueFor(layer, '#2d2c2c');
        entryDetailsBackground.setValueFor(layer, '#3b3b3b');
        this.interop.postMessageToClient("setDarkTheme");
        break;
      default:
        localStorage.setItem('theme', Theme.Light);
        this.theme = Theme.Light;
        break;
    }
  }

  getTheme(): Theme {
    return this.theme;
  }

  
}
