import { Component, OnInit } from '@angular/core';
import { ICrmData, callEntry, callResult, customData, dataType, teamsCallType } from 'src/app/models/caller.model';
import { AcsService } from 'src/app/service/acs.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { CopyButtonComponent } from "../copy-button/copy-button.component";
import { TooltipDirective } from '../tooltip.directive';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-caller-info',
    templateUrl: './caller-info.component.html',
    styleUrls: ['./caller-info.component.css'],
    standalone: true,
    imports: [CopyButtonComponent, TooltipDirective, CommonModule]
})
export class CallerInfoComponent implements OnInit {
  call: callEntry | undefined;
  crmData: customData[] = [];
  crmDataType = dataType;
  teamsType = teamsCallType;
  result = callResult;

  constructor(private acsService: AcsService, private webViewInterop: WebViewInteropService) { }
  ngOnInit(){
    this.acsService.updateCallEvent$.subscribe((result)=>{ //we are using event emitters here
      this.call = result;
      this.getCustomData();
    })
  }

  async getCustomData(){
    if(this.call?.customPromise)
    {
      this.crmData = (await this.call?.customPromise as ICrmData).custom!;
    }
    else
    {
      this.crmData = [];
    }
  }

  openLink(url: string)
  {
    if(url.startsWith("http"))
    {
      this.webViewInterop.openLink(url);
    }
    else
    {
      this.webViewInterop.openLink("http://" + url);
    }
  }

  openPhone(url: string)
  {
    this.webViewInterop.openLink("callto:"+ url.replace(" ",""));
  }
}
