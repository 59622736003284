import { Component, ComponentRef, OnInit, ViewChild } from '@angular/core';
import { ConnectorDirective } from '../dynamic-connector-directive/dynamic-connector-directive';
import { ConnectorComponent } from '../connector/connector.component';
import { ErrorResponse } from 'src/app/models/alert.model';
import { ActionMapping } from 'src/app/models/caller.model';
import { ConfigService } from 'src/app/service/config.service';
import { LoggerService } from 'src/app/service/logger.service';
import { NgIf } from '@angular/common';

export interface IConnector {
  class: any,
  name: string
}

export interface IConnectorMapping {
  key: string,
  table: string,
  value: string
}

export interface IConnectorScheme {
  title: string,
  value: string,
  type: string,
  table: string
}

export interface ICrmQuery {
  body: string,
  mapping: IConnectorMapping[],
  query: string,
  table: string
}

export interface IConnectorResponse {
  error: ErrorResponse,
  data: [
    {
      connectorId: string,
      connectorType: string,
      connectorName: string,
      connectorSetting: {
        mainFqdn: string,
        mainPath: string,
        applicationId: string
      },
      crmQueries: ICrmQuery[],
      schema: IConnectorScheme[],
      actions: ActionMapping[]
    }
  ]
}

//const httpConnectorEndpoint = "https://teamsxrmreceiver.itk-assistant.cloud/api/v1.0/CRMConnectors?code=0UJTsUOmhSDZBzcryGkij5TTyBvm3e_pYNB8uxF8OglAAzFuUz-sfA==";


/*
This component is needed to load a connector component dynamically using lazy loading
*/
@Component({
    selector: 'app-dynamic-connector',
    templateUrl: './dynamic-connector.component.html',
    styleUrls: ['./dynamic-connector.component.css'],
    standalone: true,
    imports: [NgIf, ConnectorDirective],
})
export class DynamicConnectorComponent implements OnInit {
  @ViewChild(ConnectorDirective, { static: true }) connectorHost!: ConnectorDirective;
  private componentRef: ComponentRef<ConnectorComponent> | undefined;

  connectorExist: boolean = false;
  debugMode: boolean = false;

  constructor(private configService: ConfigService, private logger: LoggerService) { }

  ngOnInit(): void {
      this.configService.configComplete$.subscribe(
        {
          next: (result: boolean) => {
            if (result) {
              //this.logger.log("Lazy load connector...");
              this.lazyLoadImport(this.configService.getCrmConfiguration());
              this.connectorExist = true;
            }
          }
        }
      );
  }

  async lazyLoadImport(config: IConnectorResponse) {
    if(config.data.length > 0)
    {
      this.logger.log("Lazy load async: ", config.data[0].connectorType);
      var a;
      var b;
      switch (config.data[0].connectorType) {
        case "MSDynamics":
          a = await import('../connector-dynamics/connector-dynamics.component')
          b = a.ConnectorDynamicsComponent;
          break;
        //case "MSDynamics":
        case "Salesforce":
          a = await import('../connector-salesforce/connector-salesforce.component')
          b = a.ConnectorSalesforceComponent;
          break;
        case "PowerAutomate":
          a = await import('../connector-powerautomate/connector-powerautomate.component');
          b = a.ConnectorPowerautomateComponent;
          break;
        default:
          this.connectorExist = false;
          break;
      }
      if(a && b)
        {
          this.createComponent(a, b, config);
        }

    }
    else
    {
      this.connectorExist = false;
    }
  }

  async lazyLoadImportString(select: string) {
    this.logger.log("Lazy load async: ", select);
    var a;
    var b;
    switch (select) {
      case "MSDynamics":
        a = await import('../connector-dynamics/connector-dynamics.component')
        b = a.ConnectorDynamicsComponent;
        break;
      case "Salesforce":
        a = await import('../connector-salesforce/connector-salesforce.component')
        b = a.ConnectorSalesforceComponent;
        break;
      default:
        break;
    }
    this.createComponentString(a, b);
  }

  createComponentString(module: any, component: any) {
    const componentToOpen = component;
    const viewContainerRef = this.connectorHost.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<ConnectorComponent>(componentToOpen);
  }

  createComponent(module: any, component: any, config: IConnectorResponse) {
    const componentToOpen = component;
    const viewContainerRef = this.connectorHost.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<ConnectorComponent>(componentToOpen);
    this.componentRef.instance.configure(config);
  }
}