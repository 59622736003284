import { CUSTOM_ELEMENTS_SCHEMA, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IAlert } from './models/alert.model';
import { DialogComponent } from './component/dialog/dialog.component';
import { AlertService } from './service/alert.service';
import { StatusbarComponent } from './component/statusbar/statusbar.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './component/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, StatusbarComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent implements OnInit{
  title = 'XRMFusion';
  alert: IAlert | undefined;

  @ViewChild("dialogContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;
  ref!: ComponentRef<DialogComponent>

  constructor(private alertService: AlertService){}

  ngOnInit(): void {
    this.alertService.alertEvent.subscribe((newAlert) => {
      this.createDialog(newAlert);
    });
  }

  createDialog(alert: IAlert)
  {
    this.ref = this.vcr.createComponent(DialogComponent);
    this.ref.instance.header = alert.type;
    this.ref.instance.content = alert.data.message;
  }

  setAlert(newAlert: IAlert){
    this.alert = newAlert;
  }
}
