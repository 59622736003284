<div class="sticky">
    <fluent-text-field appearance="outline" placeholder="Search" style="width: 100%;" #filterInput></fluent-text-field>
</div>
@if(isLoading)
{
<fluent-progress-ring />
}
<div class="history scrollable">
    <div>
        @for(entry of groupByDate('timestamp'); track $index)
        {
        <div class="badge sticky"> {{entry.key | date:'EEEE dd MMMM yyyy'}}</div>
        <app-call-history-entry *ngFor="let data of entry.value" [entry]="data" />
        }
        @empty {<div>No entries found</div>}
    </div>
</div>