import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { basicConfig } from 'src/app/models/global.model';
import { ConfigService } from 'src/app/service/config.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.css'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UpdateComponent implements OnInit {
  config: basicConfig | undefined;
  constructor(private webViewInterop: WebViewInteropService, private configService: ConfigService) { }


  ngOnInit(): void {
    this.config = this.configService.getBasicConfig();
  }

  openLink(url: string) {
    this.config = this.configService.getBasicConfig();
    if (url.startsWith("http")) {
      this.webViewInterop.openLink(url);

    }
    else {
      this.webViewInterop.openLink("http://" + url);
    }
  }
}
