<div class="menu">
  <a (click)="toggleMenu()" class="menuButton">
    <img class="icons" src="./image/menu.png">
  </a>
  <fluent-menu class="menu-content-left" *ngIf="isMenuActive">
    <fluent-menu-item (click)="toggleInfo()">About</fluent-menu-item>
    <fluent-menu-item (click)="toggleSetting()">Settings</fluent-menu-item>
    <fluent-divider/>
    <fluent-menu-item *ngIf="isAuthenticated" (click)="logout()">Logout</fluent-menu-item>
    <fluent-menu-item (click)="closeApp()">Quit</fluent-menu-item>
  </fluent-menu>
</div>

<app-dialog *ngIf="isInfoDialogActive">
  <div header>XRMFusion - About</div>
  <div content>
    <p style="font-weight: bold;">General</p>
    <div style="display: flex; flex-direction: column; gap: 5px; border-radius: 4px; padding: 4px; background: var(--theme-entry-background-color);">
      Serverversion: {{environmentVersion}}
      <br>
      Clientversion: {{config?.clientVersion}}
    </div>


    <p style="font-weight: bold;">Credits</p>
    <div style="display: flex; flex-direction: column; gap: 5px; border-radius: 4px; padding: 4px; background: var(--theme-entry-background-color);">
      <a (click)="openLink('https://www.flaticon.com/free-icons/incoming-call')" title="incoming call icons">Incoming
          call icons
          created
          by SeyfDesigner - Flaticon</a>
      <a (click)="openLink('https://www.flaticon.com/free-icons/user')" title="user icons">User icons created by
          Freepik -
          Flaticon</a>
      <a (click)="openLink('https://www.flaticon.com/free-icons/copy')" title="copy icons">Copy icons created by
          Catalin Fertu -
          Flaticon</a>
      <a (click)="openLink('https://www.flaticon.com/free-icons/success')" title="success icons">Success icons created
          by dmitri13 -
          Flaticon</a>
      <a (click)="openLink('https://www.flaticon.com/uicons')" title="UIcons">UIcons by Flaticon</a>
    </div>
    <p><fluent-divider/></p>
    <div style="display: grid; justify-items: center;">
      <p style="font-weight: bold;">© ITK Communications GmbH</p>
      <div>
        <a (click)="openLink('https://service.medialine.ag/intranet/display/XKB/Data+Privacy+Statement')" title="Privacy Statement">Privacy Statement</a> | <a (click)="openLink('https://service.medialine.ag/intranet/display/XKB/License+Agreement')" title="License Agreement">License Agreement</a>
      </div>
    </div>
  </div>
  <fluent-button (click)="toggleInfo()" actions>Close</fluent-button>
</app-dialog>

<app-dialog *ngIf="isSettingActive">
  <div header>XRMFusion - Settings</div>
  <div content>
    <p style="font-weight: bold;">Appearance</p>
    <div style="display: flex; flex-direction: column; gap: 5px; border-radius: 4px; padding: 4px; background: var(--theme-entry-background-color);">
      Theme
      <div>
        <fluent-button (click)="changeTheme(themeType.Light)" [disabled]="currentTheme == 'light'">Light</fluent-button>
        <fluent-button (click)="changeTheme(themeType.Dark)" [disabled]="currentTheme == 'dark'">Dark</fluent-button>
      </div>
      <fluent-divider/>
      Tray icon
      <div>
        <fluent-switch [checked]="config?.useTrayIcon" (change)="changedTray($event)">
          <span slot="checked-message">On</span>
          <span slot="unchecked-message">Off</span>
        </fluent-switch>
      </div>
      <fluent-divider/>
      Zoom-Level
      <div>
        <fluent-select title="Select a section" (change)="changed($event)" value="{{config?.zoomFactor}}" style="width: 100%; min-width: 100%;">
          <fluent-option value="0.75">75%</fluent-option>
          <fluent-option value="1">100%</fluent-option>
          <fluent-option value="1.25">125%</fluent-option>
          <fluent-option value="1.5">150%</fluent-option>
        </fluent-select>
      </div>
    </div>

    <p style="font-weight: bold;">Location</p>
    <div style="display: flex; flex-direction: column; gap: 5px; border-radius: 4px; padding: 4px; background: var(--theme-entry-background-color);">
      Call History
      <div style="display: grid; grid-template-columns: auto min-content;"><fluent-text-field appearance="outline" placeholder="{{config?.callHistoryPath}}" disabled></fluent-text-field><fluent-button (click)="openPath(config!.callHistoryPath)">Open</fluent-button></div>
      <fluent-divider/>
      Logs
      <div style="display: grid; grid-template-columns: auto min-content;"><fluent-text-field appearance="outline" placeholder="{{config?.logPath}}" disabled></fluent-text-field><fluent-button (click)="openPath(config!.logPath)">Open</fluent-button></div>
    </div>
  </div>
  <fluent-button appearance="accent" (click)="saveSetting()" actions>Save</fluent-button>
  <fluent-button (click)="toggleSetting()" actions>Close</fluent-button>
</app-dialog>
