import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { fluentButton, fluentCheckbox, fluentCombobox, fluentDivider, fluentMenu, fluentMenuItem, fluentOption, fluentProgressRing, fluentSelect, fluentSwitch, fluentTab, fluentTabPanel, fluentTabs, fluentTextField, fluentTooltip, provideFluentDesignSystem } from '@fluentui/web-components';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppService } from './app/service/app.service';
import { AlertService } from './app/service/alert.service';
import { WebViewInteropService } from './app/service/web-view-interop.service';
import { AcsService } from './app/service/acs.service';
import { AuthService } from './app/service/auth.service';
import { CustomHttpInterceptor } from './app/service/custom-http-interceptor';
import { provideHttpClient, withInterceptors, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthConfigService } from './app/service/auth-config.service';
import { provideAuth, StsConfigLoader, OpenIdConfiguration, StsConfigHttpLoader, authInterceptor, AbstractSecurityStorage, DefaultLocalStorageService } from 'angular-auth-oidc-client';

const authFactory = (configService: AuthConfigService) => {
  var configAzureAd$ = configService.getConfigAsync("AzureAd");
  var configAzure$ = configService.getConfigAsync("Acs");

  /*
  We have to get the configuration from the local storage here, because the library cannot get data via http with the http interceptor. The interceptor is not ready 
   */
  if(sessionStorage.getItem("CRMConf"))
  {
    const configConnector$ = new Observable<OpenIdConfiguration>(
      observer => {
        observer.next(JSON.parse(sessionStorage.getItem("CRMConf")!));
          observer.complete();
      }
    )
    return new StsConfigHttpLoader([configAzureAd$, configAzure$, configConnector$]); 
  }
  else
  {
    return new StsConfigHttpLoader([configAzureAd$, configAzure$]); 
  }
};

function initializeApp(initService: AppService) {
    return () => {return initService.Init()}; // we have to return a function here
}

if (environment.production) {
  enableProdMode();
}

provideFluentDesignSystem().register(
  fluentTab(),
  fluentTabs(),
  fluentTabPanel(),
  fluentButton(),
  fluentTextField(),
  fluentDivider(),
  fluentMenu(),
  fluentMenuItem(),
  fluentProgressRing(),
  fluentTooltip(),
  fluentCheckbox(),
  fluentCombobox(),
  fluentOption(),
  fluentSelect(),
  fluentSwitch(),
)

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule),
        provideAuth({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [AuthConfigService]
            }
        }),
        provideHttpClient(withInterceptors([authInterceptor()])),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        {
            provide: AbstractSecurityStorage,
            useClass: DefaultLocalStorageService
        },
        AuthService,
        AcsService,
        WebViewInteropService,
        {
            provide: ErrorHandler,
            useExisting: AlertService //we have to use useExisting here because, we need to provide the CustomErrorHandler for the alert component. Otherwise we would have 2 different instances, so the EventEmitter will not work
        },
        AlertService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [AppService] //Inject service
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .catch(err => console.error(err));
