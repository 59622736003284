import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private logger: LoggerService) { }

  private resourceMap : string[] = [
    environment.apiUri + environment.connectorConfQuery+ environment.apiCode,
    environment.apiUri + environment.telemetryConfQuery+ environment.apiCode,
    environment.apiUri + environment.acsConfQuery + environment.apiCode,
    environment.apiUri + environment.connectorAuthQuery + environment.apiCode,
    environment.apiUri + environment.connectorAuthQuery + environment.apiCode + "&local"
  ]

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.log("Intercept : ", req.url , "=", this.resourceMap.includes(req.url));
    if(this.resourceMap.includes(req.url))
    {
      return next.handle(req.clone({setHeaders: {"Authorization": "Bearer " + this.getIdToken()}}));
    }
    else
    {
      return next.handle(req);
    }
  }

  getIdToken() : string | undefined
  {
    return this.authService.getIdToken();
  }
}
