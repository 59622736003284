import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ICrmData, callEntry, callResult, dataType, teamsCallType } from 'src/app/models/caller.model';
import { CallDataManagerService } from 'src/app/service/call-data-manager.service';
import { ActionButtonsComponent } from '../action-button/action-buttons.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { LoggerService } from 'src/app/service/logger.service';

@Component({
  selector: 'app-call-history-entry',
  templateUrl: './call-history-entry.component.html',
  styleUrls: ['./call-history-entry.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ActionButtonsComponent, DataTableComponent]
})
export class CallHistoryEntryComponent implements AfterViewInit{
  //#region Component Properties
  @Input() entry!: callEntry;
  isDetailsVisible: boolean = false;
  type = dataType;
  teamsType = teamsCallType;
  result = callResult;
  //#endregion

  constructor(private cdr: ChangeDetectorRef, private callDataManager: CallDataManagerService, private logger: LoggerService){}

  ngAfterViewInit(): void {
      if(this.entry.customPromise) //await for customData only if a promise exists
      {
        this.logger.log("Resolving custom data for call entry.");
        this.getCustomData();
      }  
  }

  //Current testing. We want to call a function from the native C# client, which the webview2 provides
  async getCustomData(){
      this.entry.crmData.custom = (await this.entry.customPromise as ICrmData).custom!;
      this.entry.crmData.actions =  (await this.entry.customPromise as ICrmData).actions!;
      this.entry.customPromise = undefined; //we want to delete the promise here. so we can avoid duplicate awaits
      //search for the object "Name". replace displayname if object exists
      var customName = this.entry.crmData.custom.find(item => item.title === "Name");
      if(customName != undefined)
      {
        this.entry.displayName = customName.value;
      }
      this.logger.log("Result after resolving: ", customName);
      this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
  }

  showDetails()
  {
    this.isDetailsVisible = !this.isDetailsVisible;
    const displayName = this.callDataManager.graphResolveName(this.entry.forwardedBy);
    if(displayName)
    {
      displayName.subscribe(
        {
          next: (result: any) => 
          {
            this.entry.forwardedBy = result.displayName;
            this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
          },
          error: (err) => 
          {
            console.log(err);
          }
        }
      );
    }
  }
}
