import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, first } from 'rxjs';
import { LoggerService } from 'src/app/service/logger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class AvatarComponent implements OnInit {
  displayName: string | undefined = "Please login"; //Variable for the template
  mail: string | undefined;
  picture: any;
  isImageLoaded: boolean = false; // Prevent from loading the image before fetched

  constructor(private http: HttpClient, private authService: OidcSecurityService, private eventService: PublicEventsService, private logger: LoggerService) { }

  ngOnInit(): void {
      this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.NewAuthenticationResult),
        first()
      )
      .subscribe((value) => {
        //this.logger.log('NewAuthenticationResult with value', value);
        if(value.value.isAuthenticated)
        {
          this.getProfile();
        }
      });
  
  }
 /*getProfile fetches the profile picture of the signed in user and get the name, mail from the authService
  try-catch is for cases like 404 no profilepic found etc. */
  getProfile() {
    this.logger.log("Fetching avatar from: ", environment.GRAPH_ENDPOINT + environment.GRAPH_PROFILE_PICTURE);
    this.http.get(environment.GRAPH_ENDPOINT + environment.GRAPH_PROFILE_PICTURE, { responseType: 'blob' })
      .subscribe(
        {
          next: (data) => {
            this.picture = URL.createObjectURL(data);
            this.isImageLoaded = true;
          },
          error: (err) => {
            this.logger.log("Avatar: ", err);
          }
        }
      );
    this.authService.getUserData("AzureAd").subscribe(profile => {
      this.displayName = profile.name;
      this.mail = profile.email;
    })
  }

 
}
