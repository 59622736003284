import { DatePipe, NgIf, NgFor } from '@angular/common';
import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';
import { callEntry } from 'src/app/models/caller.model';
import { AcsService } from 'src/app/service/acs.service';
import { CallDataManagerService } from 'src/app/service/call-data-manager.service';
import { LoggerService } from 'src/app/service/logger.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { CallHistoryEntryComponent } from '../call-history-entry/call-history-entry.component';

@Component({
    selector: 'app-call-history',
    templateUrl: './call-history.component.html',
    styleUrls: ['./call-history.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush, //we don't to check for changes. so we can also use the onpush strategy. Please mark as dirty if changes are done
    standalone: true,
    imports: [NgIf, NgFor, CallHistoryEntryComponent, DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallHistoryComponent implements OnInit, AfterViewInit {
  //#region Component Properties
  private readonly maxEntries = 150;
  data: callEntry[] = [];
  filteredData: callEntry[] = this.data; //pass the reference of data to filteredData
  isLoading: boolean = false;
  @ViewChild("filterInput") filterInput!: ElementRef; //variable for the search input
  //#endregion

  constructor(private acsService: AcsService, private cdr: ChangeDetectorRef, private webViewInterop: WebViewInteropService, private callDataManager: CallDataManagerService, private logger: LoggerService) { }

  ngOnInit(): void {
    this.acsService.finishedCallEvent$.subscribe((newData: callEntry) => {//Subscribe to the emitter and get notified if a call is finished
      this.data.unshift(...[newData]); //add the new callEntry at the beginning of this array
      this.logger.log("Creating new call entry");
      this.callDataManager.saveCallData(newData);
      this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
    });

    this.webViewInterop.registerEventListener('message', (arg: any) => {//for more informations look into the global interface
      this.logger.log("History data: ", arg.data);
      this.data = arg.data.slice(0, this.maxEntries);
      this.filteredData = this.data;
      this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
    });
    this.getCallHistoryFromLocal();
  }

  getCallHistoryFromLocal(){
    this.logger.log("Get call history...");
    try{
      this.data = JSON.parse(this.webViewInterop.getCallHistory()).slice(0, this.maxEntries);
      this.filteredData = this.data;
      this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
    }
    catch
    {
      this.logger.log("Get call history [LEGACY]...");
      this.webViewInterop.postMessageToClient("initializeCompleted"); //we want to signal the native client that the initialization is completed
    }
  }

  ngAfterViewInit(): void {
    //we want to avoid a searching every keyup event in filterInput. so we have to implement a debounce
    fromEvent(this.filterInput.nativeElement, 'keyup')
    .pipe(debounceTime(500))
    .subscribe((value: any) => { this.search(this.filterInput.nativeElement.value) });
  }

  //this function is used for searching
  search(searchString: string | null) {
    this.logger.log("Searching...: ", searchString);
    this.isLoading = true;
    if (!searchString) {
      this.filteredData = this.data;
    }
    else {
      this.filteredData = this.data.filter(entry => entry.displayName.toString().toLowerCase().includes(searchString.toLowerCase()) || entry.number.toString().includes(searchString.toLowerCase()) || entry.forwardedBy?.toString().toLowerCase().includes(searchString.toLowerCase()) || entry.acceptedBy?.toString().toLowerCase().includes(searchString.toLowerCase()) || entry.transferredBy?.toString().toLowerCase().includes(searchString.toLowerCase()) || entry.crmData.custom?.find(val => val.value?.toString().toLowerCase().includes(searchString.toLowerCase())));
    }

    this.isLoading = false;
    this.cdr.markForCheck(); //we have to mark as dirty. so zonejs can check for changes in the next cycle
  }

  //this fn is used to group the data by date. We have to pass the timestamp string here
  groupByDate(property: string) {
    const groupedCollection = this.filteredData.reduce((previous: any, current: any) => {
      const datePip = new DatePipe("en-US"); //we are using a datepipe here to transform the timestamp. It does not matter that we are choosing en-US here because we only need to sort/group
      if (!previous[datePip.transform(current[property], "dd MMMM yyyy")!]) {
        previous[datePip.transform(current[property], "dd MMMM yyyy")!] = [current];
      } else {
        previous[datePip.transform(current[property], "dd MMMM yyyy")!].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
