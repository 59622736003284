import { ErrorHandler, EventEmitter, Injectable, Output } from '@angular/core';
import { AlertType, IAlert } from '../models/alert.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AlertService implements ErrorHandler{
  @Output() alertEvent = new EventEmitter<IAlert>();
  private alert: IAlert = {
    id: 0,
    type: AlertType.Error,
    popup: false,
    data: {
      code: 100,
      message: '',
      useTimeout: true
    }
  }

  constructor() { }

  handleError(error: unknown): void {
    if (error instanceof HttpErrorResponse)
    {
      this.alert = {
        id: 0,
        type: AlertType.Error,
        popup: false,
        data: {
          code: error.status,
          message: error.message,
          useTimeout: true
        }
      }
      this.alertEvent.emit(this.alert);
    }
    else
    {
      if(error instanceof Error)
      {
        //console.log("AlertService Custom: ", error);
        this.alert = {
          id: 0,
          type: AlertType.Error,
          popup: false,
          data: {
            message: error.message,
            useTimeout: true
          }
        }
        this.alertEvent.emit(this.alert);
      }
    }
    //console.warn('Error by CustomErrorHandler: ', error);
  }
}