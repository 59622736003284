import { Component, inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  standalone: true
})
export class AuthenticationComponent {
  private readonly oidc = inject(OidcSecurityService);

  ngOnInit(): void {
    this.oidc.checkAuthMultiple().subscribe(response => console.log(response));
  }
}
