<div class="header-container">
    <div class="nav-container">
        <div class="nav-container-item">
            <app-menu/>
        </div>
    </div>
    <img class="icons" src="./image/XRM_Logo.svg">
    <div class="avatar-container">
        <app-avatar/>
    </div>
</div>