export interface AlertResponse {

    code?: number,
    message: string,
    useTimeout: boolean
}

export interface ErrorResponse {

    errorCode?: number,
    errorMessage: string

}

export enum AlertType {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',

}

export interface IAlert {
    id: number,
    type: AlertType,
    popup: boolean,
    data: AlertResponse,
}
