import { AfterContentInit, Component, OnInit} from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { environment } from 'src/environments/environment';
import { NgClass } from '@angular/common';

/*
This component will trigger the login flow with MSAL
Please look into app.module.ts to see the configuration for MSAL
*/
@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css'],
    standalone: true,
    imports: [NgClass]
})
export class LoginPageComponent implements AfterContentInit, OnInit{
  environmentText = environment.type;
  environmentVersion = environment.version;
  isAuthenticated: boolean = true;

  constructor(private authService: AuthService, private webViewInterop: WebViewInteropService) { }

  ngOnInit() {

  }

  async ngAfterContentInit() {
    await this.authService.checkAuthentication();
    this.autoLogin();
  }

  async loginSilent()
  {
    this.isAuthenticated = true;
    try
    {
      this.isAuthenticated = await this.authService.loginMultiplePopup();
    }
    catch
    {

    }
  }

  async autoLogin()
  {
    try
    {
      this.isAuthenticated = await this.authService.autoLogin();
    }
    catch
    {
      this.isAuthenticated = false;
    }

  }

  reload() {
    window.location.reload();
  }

  closeApp() {
    this.webViewInterop.postMessageToClient("closeAppEvent");
  }

}
