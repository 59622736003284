<div class="overlay">
    <div class="dialog scrollable">

            <div class="header">
                <ng-content select="[header]"></ng-content>
                {{header}}
                <fluent-divider></fluent-divider>
            </div>
            
            <div class="content">
                <ng-content select="[content]"></ng-content>
                {{content}}
            </div>
            <div class="actions">
                <div class="actions-pad">
                    <ng-content select="[actions]"></ng-content>
                </div>
                <div *ngIf="content != undefined" class="actions-pad">
                    <fluent-button (click)="reload()">Reload</fluent-button>
                    <fluent-button (click)="closeApp()">Close App</fluent-button>
                </div>
            </div>

    </div>
</div>