import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppService, Theme} from 'src/app/service/app.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService, IClientConfig } from 'src/app/service/config.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../dialog/dialog.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    standalone: true,
    imports: [NgIf, DialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MenuComponent implements OnInit{
  //#region Variables
  isMenuActive: boolean = false;
  isInfoDialogActive: boolean = false;
  isSettingActive: boolean = false;

  currentTheme : Theme = Theme.Light;
  themeType = Theme; //in order to use the enum in our template, we have to reference to the enum
  isAuthenticated: boolean = false;
  environmentVersion = environment.version;
  config: IClientConfig | undefined;
  //#endregion

  constructor(private elementRef: ElementRef, private authService: AuthService, private configService: ConfigService, private webViewInterop: WebViewInteropService, private appService: AppService, private oidcService: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.currentTheme = this.appService.getTheme();
    this.oidcService.isAuthenticated$.pipe(

      ).subscribe(result => {
        if (result.isAuthenticated) {
          this.isAuthenticated = true;
        }
      });
  }

  //Listen to clicks and close the menu if clicked outside of this menu
  @HostListener('document:click', ['$event.target'])
  clickOutside(target: any) {
    if(!this.elementRef.nativeElement.contains(target))
    {
      this.closeMenu();
    }
  }

  changeTheme(newTheme: Theme){
    this.appService.changeTheme(newTheme);
    this.currentTheme = this.appService.getTheme();
    this.closeMenu();
  }
  
  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
  }

  closeMenu(){
    this.isMenuActive = false;
  }

  toggleInfo() {
    this.isInfoDialogActive = !this.isInfoDialogActive;
    this.config = this.configService.getClientConfiguration();
    this.closeMenu();
  }

  toggleSetting() {
    this.isSettingActive = !this.isSettingActive;
    if (this.isSettingActive) {
      this.config = this.configService.getClientConfiguration();
    }
    this.closeMenu();
  }

  changed(event: any) {
    console.log("Select: ", event, event.target.value);
    if(this.config)
    {
      this.config.zoomFactor = event.target.value;
    }
  }

  changedTray(event: any) {
    console.log("Select: ", event, event.target.checked);
    if(this.config)
    {
      this.config.useTrayIcon = event.target.checked;
    }
  }

  saveSetting() {
    if (this.config) {
      this.configService.setClientConfiguration(this.config);
      console.log("Settings: ", this.config);
    }

    this.toggleSetting()
  }

  closeApp() {
    this.webViewInterop.postMessageToClient("closeAppEvent");
    this.closeMenu();
  }

  logout(){
    this.authService.logout();
    this.isAuthenticated = false;
  }

  openLink(url: string)
  {
    if(url.startsWith("http"))
    {
      this.webViewInterop.openLink(url);
    }
    else
    {
      this.webViewInterop.openLink("http://" + url);
    }

  }

  openPath(path: string)
  {
    this.webViewInterop.openLink(path);
  }
}