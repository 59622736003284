import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit} from '@angular/core';
import { AcsService } from 'src/app/service/acs.service';
import { LoggerService } from 'src/app/service/logger.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../dialog/dialog.component';
import { CallHistoryComponent } from '../call-history/call-history.component';
import { DynamicConnectorComponent } from '../crm-connector/dynamic-connector/dynamic-connector.component';
import { NgIf } from '@angular/common';
import { CallerInfoComponent } from '../caller-info/caller-info.component';
import { AuthService } from 'src/app/service/auth.service';

/*
This is the main component, which will be rendered after a user signs in
*/
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    standalone: true,
    imports: [CallerInfoComponent, NgIf, DynamicConnectorComponent, CallHistoryComponent, DialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainComponent implements OnInit {
  constructor(private acsService: AcsService, private logger: LoggerService, private authService: AuthService) { }
  debugMode: boolean = false;
  isLoading: boolean = true;
  a: number =0;


  ngOnInit(): void {
    if(!environment.production)
    {
      this.debugMode = true;
    }
    //we have to add a 1s delay here. otherwise we can't execute this due to incosistency for the Acs Token
    setTimeout(async ()=>{
      this.initCallAgent();
    },1000);

   
  }

  initCallAgent() {
    this.acsService.initCallAgentRefresher().then(
      result => {
        this.logger.log("MAIN: ", result);
        this.logger.log("Initializing completed...");
        this.isLoading = false;
      }
    )
    .catch(err => console.log(err)); //get acs token from API (Azure Functions)

  }
}
