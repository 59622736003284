<div class="main-container scrollable">

    <app-caller-info></app-caller-info>
    <fluent-tabs activeid="callerInfo" class="scrollable custom-grid">
        <fluent-tab id="callerInfo" class="test">Information</fluent-tab>
        <fluent-tab id="callHistory">History</fluent-tab>
        <fluent-tab *ngIf="debugMode" id="test">Test</fluent-tab>
        <fluent-tab-panel id="callerInfoPanel" class="scrollable full">
            <app-dynamic-connector></app-dynamic-connector>
        </fluent-tab-panel>
        <fluent-tab-panel id="callHistoryPanel" class="scrollable full">
            <app-call-history class="scrollable history"></app-call-history>
        </fluent-tab-panel>
        <fluent-tab-panel *ngIf="debugMode" id="testPanel" class="scrollable full">
            <fluent-text-field appearance="outline" placeholder="Search" style="width: 100%;"
                #searchInput></fluent-text-field>
        </fluent-tab-panel>
    </fluent-tabs>
</div>

<ng-template #dialogContainerRef></ng-template>

<app-dialog *ngIf="isLoading">
    <div header>XRMFusion</div>
    <div content>Initializing...<fluent-progress-ring class="initIndicator" /></div>
</app-dialog>