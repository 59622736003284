import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogComponent {
  header: string | undefined;
  content: string | undefined;

  constructor(private webViewInterop: WebViewInteropService){}

  reload() {
    window.location.reload();
  }

  closeApp() {
    this.webViewInterop.postMessageToClient("closeAppEvent");
  }
}
