import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './component/main/main.component';
import { LoginPageComponent } from './component/login-page/login-page.component';
import { AuthenticationComponent } from './component/authentication/authentication.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UpdateComponent } from './component/update/update.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
    //component: LoginPageComponent, 
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'callback',
    component: AuthenticationComponent,
  },
  {
    path: 'update',
    component: UpdateComponent,
  },
  {
    path: 'home',
    component: MainComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: '**', //Wildcard to show 404 page
    component: LoginPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
