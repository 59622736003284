import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  constructor(private httpClient: HttpClient) { }

  getConfigAsync(id: string | undefined) : Observable<OpenIdConfiguration> {
    const configAzureAd : OpenIdConfiguration = {
      configId: 'AzureAd',
      authority: environment.msConfig.authority,
      redirectUrl: environment.msConfig.redirectUri,
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.msConfig.clientId,
      scope: 'openid profile email offline_access User.Read',
      responseType: 'code',
      logLevel: LogLevel.Debug,
      issValidationOff: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      useRefreshToken: true,
      silentRenew: true,
      autoUserInfo: false,
      ignoreNonceAfterRefresh: true,
      renewTimeBeforeTokenExpiresInSeconds: 600,
      secureRoutes: ['https://graph.microsoft.com/v1.0/me', 'https://graph.microsoft.com/v1.0/users/']
    }

    const configAzure : OpenIdConfiguration = {
      configId: 'Acs',
      authority: environment.msConfig.authority,
      redirectUrl: environment.msConfig.redirectUri,
      postLogoutRedirectUri: environment.msConfig.redirectUri,
      clientId: environment.msConfig.clientId,
      scope: 'openid email offline_access https://auth.msft.communication.azure.com/Teams.ManageCalls https://auth.msft.communication.azure.com/Teams.ManageChats', //we need openid email and offline_access. so the lib can detect the authentication status
      responseType: 'code',
      logLevel: LogLevel.Debug,
      issValidationOff: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      useRefreshToken: true,
      silentRenew: true,
      autoUserInfo: false,
      ignoreNonceAfterRefresh: true,
      renewTimeBeforeTokenExpiresInSeconds: 600,
      secureRoutes: [' ']
    }

    switch (id) {   
      case "AzureAd":
        if(true)
        {
          configAzureAd.logLevel = LogLevel.Error
        }
        return new Observable<OpenIdConfiguration>(
          observer => {
            observer.next(configAzureAd);
            observer.complete();
          }
        )
      case "Acs":
        //console.log("Acs");
        if(true)
        {
          configAzure.logLevel = LogLevel.Error
        }
        return new Observable<OpenIdConfiguration>(
          observer => {
            observer.next(configAzure);
            observer.complete();
          })
      default:
        return this.httpClient.get<any>(`https://...`).pipe(
          map((customConfig: any) => {
            return {
              authority: customConfig.authority,
              /* Your config mapping here */
            };
          })
        );
    }

  }

}